<template>
  <div id="login">
    <header>
      <h6>BOTLRS</h6>
    </header>

    <section>
      <b-container>
        <b-row>
          <b-col
            v-if="!isLoaded"
            class="card form-wrapper"
            xl="6"
            md="8"
            offset-md="2"
            offset-xl="3"
          >
            <b-spinner class="m-25 text-center" label="Busy"></b-spinner>
          </b-col>

          <b-col
            v-else-if="showLoginForm && isLoaded"
            class="card form-wrapper"
            xl="6"
            md="8"
            offset-md="2"
            offset-xl="3"
          >
            <h2>Sign in</h2>

            <p>
              Don't have an account?
              <a @click="toggleForm()">Please sign up.</a>
            </p>

            <b-row>
              <b-col md="6" class="slack-btn auth-btn"> 
                <a
                  href="https://slack.com/oauth/v2/authorize?client_id=204969246097.1780454977030&user_scope=identity.basic,identity.email,identity.avatar&scope=app_mentions:read,channels:read,chat:write,chat:write.customize,chat:write.public,commands,files:read,groups:read,im:history,im:read,im:write,links:read,links:write,mpim:read,team:read,users:read,users:read.email,chat:write.customize&source=popup&redirect_uri=https://standup.11ts-e.com/login"
                >
                  <b-row>
                    <b-col cols="3" class="img_span">
                      <div>
                        <img
                          src="../assets/img/slack.png"
                          alt=""
                          height="30px"
                          width="30px"
                        />
                      </div>
                    </b-col>
                    <b-col cols="9" class="auth-btn-txt"
                      ><span>Slack</span></b-col
                    >
                  </b-row>
                </a>
              </b-col>
              <b-col md="6" class="microsoft-btn auth-btn">
                <a>
                  <b-row>
                    <b-col cols="3" class="img_span">
                      <div>
                        <img
                          src="../assets/img/microsoft.png"
                          alt=""
                          height="30px"
                          width="30px"
                        />
                      </div>
                    </b-col>
                    <b-col cols="9" class="auth-btn-txt"
                      ><span>Microsoft</span></b-col
                    >
                  </b-row>
                </a>
              </b-col>
              <b-col md="6" class="google-btn auth-btn">
                <a>
                  <b-row>
                    <b-col cols="3" class="img_span">
                      <div>
                        <img
                          src="../assets/img/search.png"
                          alt=""
                          height="30px"
                          width="30px"
                        />
                      </div>
                    </b-col>
                    <b-col cols="9" class="auth-btn-txt"
                      ><span>Google</span></b-col
                    >
                  </b-row>
                </a>
              </b-col>
              <b-col md="6" class="facebook-btn auth-btn">
                <a>
                  <b-row>
                    <b-col cols="3" class="img_span">
                      <div>
                        <img
                          src="../assets/img/facebook.png"
                          alt=""
                          height="30px"
                          width="30px"
                        />
                      </div>
                    </b-col>
                    <b-col cols="9" class="auth-btn-txt"
                      ><span>Facebook</span></b-col
                    >
                  </b-row>
                </a>
              </b-col>
            </b-row>

            <form @submit.prevent class="mt-2">
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    id="login-email-group"
                    label="Email"
                    label-for="login-email"
                  >
                    <b-form-input
                      v-model.trim="loginForm.email"
                      id="login-email"
                      type="email"
                      placeholder="Email address"
                      required
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12">
                  <b-form-group
                    id="login-password-group"
                    label="Password"
                    label-for="login-password"
                  >
                    <b-form-input
                      v-model.trim="loginForm.password" 
                      id="login-password"
                      type="password"
                      placeholder="Enter password"
                      required
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row class="mt-3">
                <b-col cols="6">
                  <a>Forgot Password</a>
                </b-col>
                <b-col cols="6" class="text-right">
                  <b-button @click="login()" variant="success"
                    >Sign in</b-button
                  >
                </b-col>
              </b-row>
            </form>

            <b-row class="mt-5">
              <b-col cols="12" class="text-center">
                <p class="small">
                  By creating the account you agree to Botlrs<br />
                  <a>Terms of Service</a> and <a>Privacy Policy</a>
                </p>
              </b-col>
            </b-row>
          </b-col>

          <b-col
            v-else
            class="card form-wrapper"
            xl="6"
            md="8"
            offset-md="2"
            offset-xl="3"
          >
            <h2>Sign up</h2>

            <p>
              Do you already have an account?
              <a @click="toggleForm()">Please sign in.</a>
            </p>

            <form @submit.prevent class="mt-2"></form>
          </b-col>
        </b-row>
      </b-container>
    </section>
  </div>
</template>

<script>
// import PasswordReset from '@/components/PasswordReset'
export default {
  components: {
    // PasswordReset
  },
  data() {
    return {
      loginForm: {
        email: "",
        password: "",
      },
      signupForm: {
        name: "",
        title: "",
        email: "",
        password: "",
      },
      showLoginForm: true,
      showPasswordReset: false,
      isLoaded: true,
    };
  },
  mounted() {
    if (this.$route.query.error) {
      this.$toast.open({
        message: "Unable to login, try again.",
        type: "error",
        duration: 5000,
        dismissible: true,
        position: "top-right",
      });
    }

    if (this.$route.query.code) {
      this.isLoaded = false;

      fetch(
        `${process.env.VUE_APP_API_URL}user/login?code=${this.$route.query.code}`
      )
        .then((response) => {
          if (response.status == 500 || response.status == 401) {
            return Promise.reject(response);
          }

          return response.json();
        })
        .then((data) => {
          this.$toast.open({
            message: "Login Successful",
            type: "success",
            duration: 5000,
            dismissible: true,
            position: "top-right",
          });
          this.$store.dispatch("updateAccessToken", data.accessToken)
            this.$store.dispatch("updateUserProfile", data.user);
        })
        .catch(async (error) => {
          this.isLoaded = true;
          const data = await error.json();
          if (error.status == 500) {
            this.$toast.open({
              message: "Internal server error",
              type: "error",
              duration: 5000,
              dismissible: true,
              position: "top-right",
            });
          } else {
            this.$toast.open({
              message: data.message,
              type: "error",
              duration: 5000,
              dismissible: true,
              position: "top-right",
            });
          }
          // console.error("There was an error!", data.message);
        });
    }
  },
  methods: {
    toggleForm() {
      this.showLoginForm = !this.showLoginForm;
    },
    togglePasswordReset() {
      this.showPasswordReset = !this.showPasswordReset;
    },
    login() {
      //   this.$store.dispatch('login', {
      //     email: this.loginForm.email,
      //     password: this.loginForm.password
      //   })
    },
    signup() {
      //   this.$store.dispatch('signup', {
      //     email: this.signupForm.email,
      //     password: this.signupForm.password,
      //     name: this.signupForm.name,
      //     title: this.signupForm.title
      //   })
    },
  },
};
</script>
